import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { AUTH_USER_INVALIDATION_QUERY_KEY } from '@query';

import {
  getTurtleClubMembership,
  verifyTurtleClubSiwe,
  VerifyTurtleClubSiweParameters,
} from '@shared/api/turtle-club';

import { AUTH_USER_QUERY_KEY } from './keys';

const TURTLE_CLUB_MEMBERSHIP_QUERY_KEY = 'turtle-club/membership';

interface UseTurtleClubMembershipParameters {
  isAuth: boolean;
}

export const useTurtleClubMembership = ({ isAuth }: UseTurtleClubMembershipParameters) => {
  return useQuery({
    queryKey: [
      TURTLE_CLUB_MEMBERSHIP_QUERY_KEY,
      AUTH_USER_INVALIDATION_QUERY_KEY,
      AUTH_USER_QUERY_KEY,
      isAuth,
    ],
    queryFn: () => getTurtleClubMembership(),
  });
};

export const useVerifyTurtleClubSiwe = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (parameters: VerifyTurtleClubSiweParameters) => {
      await verifyTurtleClubSiwe(parameters);
    },
    onSuccess: () => {
      console.log('Invalidating keys');

      queryClient.invalidateQueries({
        queryKey: [TURTLE_CLUB_MEMBERSHIP_QUERY_KEY],
      });
    },
  });
};
